import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Excited to report that Paybefore (the leading information provider to prepaid, mobile and emerging payments industry professionals) has selected PayPerks as a 2014 Paybefore Awards winner in the ‘One to Watch’ category. This award recognizes the up-and-coming prepaid/emerging payments business offering the program, product or service with staying power and the potential to move prepaid/emerging payments forward—over the long term.`}</p>
    <p>{`PayPerks was selected by a panel of five industry experts who served as judges for this year’s competition. The Best-in-Category will be determined by this panel in combination with a popular vote (visit `}<a parentName="p" {...{
        "href": "http://paybefore.com/awards/2014-paybefore-awards-sixth-judge-voting/"
      }}>{`Paybefore.com's voting page`}</a>{` through Feb 2nd to show your PayPerks-love).`}</p>
    <p>{`Winners will be announced at the All Payments Expo., at 1:20 PM on March 4th so stop by and say hi if you’re there!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1247px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "77.25000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABbH9FgsJ//8QAHRAAAQIHAAAAAAAAAAAAAAAAAgABAxAREhMhMv/aAAgBAQABBQIs9ReMh5s20v/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABwQAAAGAwAAAAAAAAAAAAAAAAABAhExQRAiof/aAAgBAQAGPwJTKuBsqugnEnn/xAAeEAABAwQDAAAAAAAAAAAAAAABABExEEFRgSFh8P/aAAgBAQABPyGM5gdMogxjBvBfafzHLSi517JQESX1T//aAAwDAQACAAMAAAAQ0M//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAEDAQE/ENDpLt//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAeEAEBAAIBBQEAAAAAAAAAAAARAQAhURAxQWGRsf/aAAgBAQABPxCotE10jLukaI85vhbQVVr4kHseM4V6Bp3xQijV+4kfAHT/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/5dfe5381338365b66b49d629bed034a8/7f61c/2014_17_Winner.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/5dfe5381338365b66b49d629bed034a8/d00b9/2014_17_Winner.webp 800w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/5dfe5381338365b66b49d629bed034a8/31e5d/2014_17_Winner.webp 1247w"],
            "sizes": "(max-width: 1247px) 100vw, 1247px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/5dfe5381338365b66b49d629bed034a8/066f9/2014_17_Winner.jpg 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/5dfe5381338365b66b49d629bed034a8/4b190/2014_17_Winner.jpg 800w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/5dfe5381338365b66b49d629bed034a8/f74b2/2014_17_Winner.jpg 1247w"],
            "sizes": "(max-width: 1247px) 100vw, 1247px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/5dfe5381338365b66b49d629bed034a8/f74b2/2014_17_Winner.jpg",
            "alt": "2014_17_Winner",
            "title": "2014_17_Winner",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      